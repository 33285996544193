<template>
  <div>
    <div class="facebook-card">
      <skeleton-loader-vue
        type="ract"
        :height="15"
        animation="wave"
        class="shimmer-accordion"
      />
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"

export default {
  name: "LongTextShimmer",
  components: {
    SkeletonLoaderVue,
  },
}
</script>

<style>
.shimmer-accordion {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}
</style>
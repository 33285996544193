<template>
  <div>
    <div class="facebook-card">
      <skeleton-loader-vue
        type="ract"
        :width="100"
        :height="15"
        animation="wave"
        :radius="10"
      />
    </div>
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"

export default {
  name: "ShortText",
  components: {
    SkeletonLoaderVue,
  },
}
</script>

<style>
</style>
<template>
  <div>
    <b-col cols="12 p-0">
      <div class="total-project-hour-dashboard">
        <div class="d-flex align-items-start justify-content-between">
          <div>
            <h3 class="mb-0 total-hours-time">
              <short-text></short-text> <short-text></short-text>
            </h3>
            <p class="time-title-dashboard"><short-text></short-text></p>
          </div>
          <p><short-text></short-text><short-text></short-text></p>
        </div>
        <div class="hr-line-dashboard" />
        <div class="all-project-progress">
          <div class="total-project-hour-user">
            <div class="d-flex align-items-center">
              <p class="user-projects-name" style="color: #2f80ed">
                <short-text></short-text>
              </p>
              <span class="user-total-hour"> <short-text></short-text></span>
            </div>
            <p><long-shimmer class="mt-1"></long-shimmer></p>
          </div>
          <div class="total-project-hour-user">
            <div class="d-flex align-items-center">
              <p class="user-projects-name" style="color: #2f80ed">
                <short-text></short-text>
              </p>
              <span class="user-total-hour"> <short-text></short-text></span>
            </div>
            <p><long-shimmer class="mt-1"></long-shimmer></p>
          </div>
          <div class="total-project-hour-user mb-5">
            <div class="d-flex align-items-center">
              <p class="user-projects-name" style="color: #2f80ed">
                <short-text></short-text>
              </p>
              <span class="user-total-hour"> <short-text></short-text></span>
            </div>
            <p><long-shimmer class="mt-1"></long-shimmer></p>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import ShortText from "./shortText.vue"
import LongShimmer from "./Longshimmer.vue"

export default {
  name: "ProgressShimmer",
  components: {
    ShortText,
    LongShimmer,
  },
}
</script>

<style>
</style>